import { css } from '@emotion/react';

export const gbglightblueradialgradientellipse = css`
  // background-image: radial-gradient(ellipse farthest-corner at 50% 50%, #fff 22%, #d6e2ee);
  // background-repeat: no-repeat;
`;

export const darkblueradialgradientellipse = css`
  // background: #0f0c29; /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #0f0c29, #302b63, #24243e); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #0f0c29, #302b63, #24243e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;
