/* eslint-disable no-undef, react/prop-types */
import { graphql, Link, navigate } from 'gatsby';
import React, { Component } from 'react';
import { Parallax, Background } from 'react-parallax';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Helmet } from 'react-helmet';
import PageTransition from '../components/PageTransition/index';
import encodeForm from '../utils/encodeForm';
import ButtonPrimary, { ButtonSecondary } from '../components/Buttons';
import { Divider1, Divider2, Divider3, Divider4, Divider4IconStyle, Divider5, Divider5IconStyle, HorizontalLine } from '../components/Dividers';
import { showSmall, hideSmall, noWrap, visuallyHidden, ulStyle, disableBrowserStyling, whiteTextShadow } from '../style/genericStyling';
import { contactInput, formSubmitButton } from '../style/formStyling';
import { gbglightblueradialgradientellipse, darkblueradialgradientellipse } from '../style/backgroundGradients';
import { Box, Flex, BoxVisible, Container } from '../style/basicStyle';
import colors from '../utils/colors';
import Hero from '../components/Hero';

class About extends Component {
  componentDidMount() {
    const { props } = this;
    props.toggleNavDark({ navBarDark: false });
  }

  render() {
    const { props } = this;
    const { data } = props;
    if (props.errors) {
      props.errors.forEach((err) => console.log('about error rendering: ', err));
      throw new Error(props.errors[0] && props.errors[0].message);
    }
    const { imageSharp } = data;
    const imageSrc = imageSharp && imageSharp.gatsbyImageDataFullWidth && imageSharp.gatsbyImageDataFullWidth.images && imageSharp.gatsbyImageDataFullWidth.images.fallback && imageSharp.gatsbyImageDataFullWidth.images.fallback.src;
    const myData = data && data.allPageDataJson.edges[0].node;
    /* BEGIN Form Functions */
    const contactSchema = Yup.object().shape(
      {
        message: Yup.string().min(10, 'Message too short!').max(250, 'Message too long!').required('Please enter your message.'),
        name: Yup.string().min(2, 'Name too Short!').max(100, 'Name too Long!').required('Full Name Required'),
        email: Yup.string().when('phone', {
          is: (phone) => !phone,
          then: Yup.string().email('Invalid email').required('Email or Phone Required.')
        }),
        phone: Yup.string().when('email', {
          is: (email) => !email,
          then: Yup.string().min(10, 'Invalid phone number').required('Email or Phone Required.')
        })
      },
      [
        ['name', 'email', 'phone', 'message'],
        ['name', 'phone', 'email', 'message'],
        ['name', 'message', 'email', 'phone'],
        ['name', 'phone', 'message', 'email'],
        ['phone', 'name', 'email', 'message'],
        ['phone', 'email', 'name', 'message'],
        ['phone', 'message', 'email', 'name'],
        ['phone', 'email', 'message', 'name'],
        ['email', 'phone', 'name', 'message'],
        ['email', 'name', 'phone', 'message'],
        ['email', 'message', 'name', 'phone'],
        ['email', 'name', 'message', 'phone'],
        ['message', 'phone', 'name', 'email'],
        ['message', 'name', 'phone', 'email'],
        ['message', 'email', 'name', 'phone'],
        ['message', 'name', 'email', 'phone']
      ]
    );
    /* END Form Functions */

    return (
      <PageTransition location={props.location}>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>About the Florida Bulldogs</title>
        </Helmet>
        <Hero
          opacity={0.5}
          toggleNavDark={props.toggleNavDark}
          navDarkActive={props.navDarkActive}
          minHeight="65vh"
          heading1={myData.title}
          heading3={myData.subtitle}
          heroImage={imageSrc}
          heading1-data-aos="fade-up"
          heading1-data-aos-duration="1500"
          heading3-data-aos="fade-in"
          heading3-data-aos-delay="750"
          heading3-data-aos-duration="2000"
        />
        {/* ********************************************************************************** */}
        {/* ********************************* Offices Begin*********************************** */}
        {/* ********************************************************************************** */}
        <Parallax>
          <div>
            <Flex textAlign="center" justifyContent="center" wrap={['wrap']}>
              <Box m={[2, 2, 2, 2]} py={[4, 5]} textAlign="center" width={[1, 800]}>
                <Container>
                  <Box id="locations" data-aos="fade-down" data-aos-duration="1500" width={[1]} py={[1, 0]} my={[3, 2]} textAlign="center" fontSize={[4, 4, 5, 6]}>
                    Our Locations
                  </Box>
                  <Box width={[1, 1, 1 / 2]} p={[2]}>
                    <Box data-aos-anchor="#locations" textAlign="left" p={[2]} data-aos="fade-up" data-aos-delay="250" data-aos-duration="750">
                      <Box py={[3]} fontSize={[3]}>
                        <Box p={[2]} style={{ fontWeight: 'bold' }}>
                          Primary Office
                        </Box>
                        <Box>1557 Auburn Lakes Dr</Box>
                        <Box>Rockledge, FL 32955</Box>
                        <Box my={[3]}>
                          <Box>
                            Tel:
                            <a href="tel:1-516-848-7642"> 516-848-7642</a>
                          </Box>
                          <Box>
                            Email:
                            <a href="mailto:info@floridabulldogshockey.com"> info@floridabulldogshockey.com</a>
                          </Box>
                        </Box>
                      </Box>
                      <Box py={[3]} fontSize={[3]}>
                        <Box p={[2]} style={{ fontWeight: 'bold' }}>
                          Home Rink
                        </Box>
                        <Box>The Ice Factory of Central Florida</Box>
                        <Box>2221 Partin Settlement rd</Box>
                        <Box>Kissimmee, FL 34744</Box>
                        <Box>
                          Tel:
                          <a href="tel:1-407-933-4259"> 407-933-4259 </a>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Flex>
          </div>
        </Parallax>
        {/* ********************************************************************************** */}
        {/* ********************************* Offices End ********************************* */}
        {/* ********************************************************************************** */}
        {/* ********************************************************************************** */}
        {/* ********************************* Form Begin ********************************* */}
        {/* ********************************************************************************** */}
        <Parallax>
          <Flex textAlign="center" justifyContent="center" wrap={['wrap']} css={gbglightblueradialgradientellipse}>
            <Box m={[3, 4]} textAlign="center" width={[1, 1, 600, 900]}>
              <Container id="contact">
                <Box id="contactUsHeader" width={[1]} textAlign="center" fontSize={[4, 4, 5, 5]}>
                  Contact Us
                </Box>
                <Container width={[1]} p={[1]}>
                  <HorizontalLine width={[1 / 2, 1 / 4, 1 / 6]} my={[2, 3]} borderColor={colors.tertiary} border={['solid 0.5px']} />
                </Container>
                <Box p={[1, 2, 3]} fontSize={[2, 3]} width={[1]}>
                  <Box textAlign="center" p={[1, 2, 3]}>
                    <Formik
                      initialValues={{ name: '', email: '', phone: '', message: '' }}
                      validationSchema={contactSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        const body = encodeForm(values, 'contact');
                        if (process.env.GATSBY_DEVELOPMENT && process.env.GATSBY_DEVELOPMENT === 'true') {
                          // note - we do not attempt to process te form locally.
                          console.log('form submission processing development environment: ', process.env.GATSBY_DEVELOPMENT);
                          fetch('/.netlify/functions/contactForm', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(values)
                          })
                            .then((result) => {
                              if (result.status === 200) {
                                console.log('contact form success for dev - submitted values: ', values, ' | res is: ', result);
                                // navigate('/success');
                              } else {
                                console.log('contact form ERROR for dev - submitted values: ', values, ' | res is: ', result);
                                // navigate('/error');
                              }
                            })
                            .catch((error) => {
                              console.log('contact form ERROR 1 for dev - submitted values: ', values, ' | error is: ', error);
                              // navigate('/error');
                            });
                        } else {
                          // console.log('form submission processing production environment...');
                          fetch('/', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                            body
                          })
                            .then((result) => {
                              if (result.status === 200) {
                                // console.log('form submission processing database insert for production environment...');
                                fetch('/.netlify/functions/contactForm', {
                                  method: 'POST',
                                  headers: { 'Content-Type': 'application/json' },
                                  body: JSON.stringify(values)
                                })
                                  .then((result2) => {
                                    if (result2.status === 200) {
                                      // console.log('Contact form database submission success');
                                      navigate('/success');
                                    } else {
                                      console.log('contact form ERROR 2 - submitted values: ', values, ' | res is: ', result2);
                                      navigate('/error');
                                    }
                                  })
                                  .catch((error) => {
                                    console.log('contact form ERROR 3 - submitted values: ', values, ' | error is: ', error);
                                    navigate('/error');
                                  });
                              } else {
                                navigate('/error');
                                console.log('form 4 ERROR - submitted values: ', values, ' | result is: ', result);
                              }
                              setSubmitting(false);
                            })
                            .catch((error) => {
                              console.log('contact form error: ', error);
                              navigate('/error');
                            });
                        }
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form name="contact" netlify="true" data-netlify="true" data-netlify-honeypot="bot-field">
                          <Field css={visuallyHidden} type="text" name="bot-field" />
                          <Flex wrap={['wrap']} justifyContent="center">
                            <Box width={[1, 1, 1, 1 / 3]} p={[2, 3]}>
                              <Field css={[disableBrowserStyling, contactInput]} type="text" name="name" autoComplete="name" placeholder="Full Name" />
                              <Box fontSize={[2]} p={[1]}>
                                <ErrorMessage name="name" component="div" />
                              </Box>
                            </Box>
                            <Box width={[1, 1 / 2, 1 / 2, 1 / 3]} p={[2, 3]}>
                              <Field css={[disableBrowserStyling, contactInput]} type="email" name="email" autoComplete="email" placeholder="Email Address" />
                              <Box fontSize={[2]} p={[1]}>
                                <ErrorMessage name="email" component="div" />
                              </Box>
                            </Box>
                            <Box width={[1, 1 / 2, 1 / 2, 1 / 3]} p={[2, 3]}>
                              <Field css={[disableBrowserStyling, contactInput]} type="tel" name="phone" autoComplete="tel" placeholder="Phone Number" />
                              <Box fontSize={[2]} p={[1]}>
                                <ErrorMessage name="phone" component="div" />
                              </Box>
                            </Box>
                          </Flex>
                          <Box width={[1]} p={[2, 3]}>
                            <Field css={[disableBrowserStyling, contactInput]} rows="5" component="textarea" type="text" name="message" placeholder="Type your request here" />
                            <Box fontSize={[2]} p={[1]}>
                              <ErrorMessage name="message" component="div" />
                            </Box>
                          </Box>
                          <Box width={[1]} p={[2, 3]}>
                            <Container>
                              <Box width={[1, 1 / 3]} textAlign="center">
                                <ButtonSecondary type="submit" css={formSubmitButton} disabled={isSubmitting}>
                                  Submit
                                </ButtonSecondary>
                              </Box>
                            </Container>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Flex>
        </Parallax>
      </PageTransition>
    );
  }
}

export const pageQuery = graphql`
  query AboutQuery {
    imageSharp(original: { src: { regex: "/aboutHero/" } }) {
      gatsbyImageDataFullWidth: gatsbyImageData(layout: FULL_WIDTH, quality: 90)
    }
    allPageDataJson(filter: { name: { eq: "about" } }) {
      edges {
        node {
          name
          title
          subtitle
        }
      }
    }
  }
`;

export default About;
