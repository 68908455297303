export function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

// form-name needs to be added to properly process the form in Netlify.

export default function encodeForm(values, formName) {
  if (!values) {
    throw new Error('encodeForm called with no values!');
  }
  if (!formName) {
    throw new Error('encodeForm called with no form name!');
  }
  return encode({
    // returns what should be sent as the body from a form...
    'form-name': formName,
    ...values
  });
}
